import React from 'react';
import { withRouter, Route, BrowserRouter, Switch, Redirect } from 'react-router-dom';
import Home from './components/top/home';

function App() {
  return (
    <BrowserRouter>
      <Switch>
        <Route exact path='/' render={(props) => <Home {...props} />} />
        <Redirect to='/' />
      </Switch>
    </BrowserRouter>
  );
}

export default withRouter(App);
