import React from 'react';

export const Logo = () => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 754.23 891.08'>
      <path
        d='M555.16,680.36c-10-17.39-24.64-30.22-42.19-40-16.4-9.1-33.74-16.17-50.75-23.88-14.15-6.42-28.35-12.65-42.21-19.69-15.09-7.65-29.54-16.24-41.22-28.6-8.26-8.74-14.2-18.67-14.94-31.41-.93-16,6-29.36,13.54-42.28,8.95-15.34,20.05-29.3,32.25-42.26,14.63-15.53,29.34-31,44.31-46.18,17.4-17.66,33.83-36.06,46.16-57.71,4.66-8.18,9.28-16.41,12.17-25.5,1.84-5.78,3.51-11.58,5.06-17.46a107,107,0,0,0,3.94-33.09c-.69-13.51-3.92-26.33-8.81-39-7.87-20.4-22.75-32.65-42.83-38.65-25-7.49-50.4-4.44-75.49.28-12.58,2.36-24.92,5.93-37.23,9.55-17.76,5.22-35,12-52.14,18.75-13.93,5.51-27.07,13-40.86,18.88-20.71,8.85-37.35,22.59-50.77,40.41a169.48,169.48,0,0,0-22.08,39.77c-4,10.11-5.41,20.39-6.72,30.87,0,1.67-.07,3.34,0,5,.46,9,5.37,13.86,14.33,14.31,17.17.87,32.21-6.27,47.12-13.21a202.36,202.36,0,0,0,32.67-19.33c5.76-4.16,11.22-9,11.7-17.15.33-5.63-2-9.74-6.1-13.27-2.51-2.16-3.16-3.94.88-5.63,8.29-3.45,16.31-7.54,24.55-11.1,9.59-4.15,19.08-8.71,29-11.81,9-2.79,17.83-6,27.21-7.52,12.29-2,24.41-4.74,36.91-1.15,7,2,15.74,10,16.22,16.89.75,10.85.59,21.7-3.36,32.15-7.29,19.28-19.28,35.57-32.81,50.69-15.31,17.1-32.26,32.63-48.68,48.66-17.56,17.15-35,34.39-50.26,53.72-13.32,16.94-24.8,34.87-30.6,56-7.5,27.35-3.37,52.34,13.91,74.88,8.57,11.16,19.1,20.28,30.78,28.19,12.75,8.64,25.89,16.56,40,22.61,9.48,4.06,18.63,8.79,28.64,11.83,5.54,1.68,11.18,5.29,16.72,6.83,11.79,3.29,23.34,7.4,35,11,10.76,3.36,21.73,6,32.43,9.71,12.61,4.32,23.73,10.76,34.29,18.53,12.36,9.1,22.34,20.46,31.19,32.93,14.41,20.3,24.73,42.54,32.11,66.24,7.16,23,12.47,46.35,12.64,70.6,0,1.79,1,3.11,1.72,4.56.58,1.11.7,2.85,2.42,2.68,1.56-.16,1.71-1.61,2.05-3,2.11-8.21,4.24-16.42,6.56-24.57,2.47-8.61,2.88-17.6,5.11-26.29,1.28-5,.62-10.41,1.31-15.69a248,248,0,0,0-6.21-95.84A183.68,183.68,0,0,0,555.16,680.36Z'
        style={{ fill: '#333' }}
      />
      <path
        d='M753.89,285.22c-.8-9.21-5.15-16.43-13.41-21.43-5.15-3.12-10.44-3.61-16.1-3.61q-80.46,0-160.93-.09c-4.4,0-6.4.74-5.72,5.68.56,4.09.25,8.31.24,12.48,0,7.93-.07,7.92,8,7.92l154.43,0c7.63,0,7.65,0,7.66,7.41q0,83,0,165.93c0,7.6,0,7.6-7.67,7.6H597.93v-.22q-63,0-125.93,0c-3.19,0-4.37,1.26-4.36,4.47q.12,52.23,0,104.45c0,2.91,1.15,4.51,3.79,5.6q8.79,3.63,17.4,7.66c3.66,1.73,4.93,1.14,4.9-3.19-.15-28.66-.08-57.31-.11-86,0-6.72-.06-6.72,6.89-6.72q80,0,159.93,0c22.31,0,44.62.3,66.93-.41,9-.28,15.7-3.92,21.22-10.68,4.43-5.44,5.47-11.74,5.47-18.43q0-78.22,0-156.43C754.09,299.87,754.52,292.5,753.89,285.22Z'
        style={{ fill: '#333' }}
      />
      <path
        d='M239.53,467.13c-1.83,0-3.67-.05-5.5-.05q-89.47,0-178.95,0c-7.66,0-15.34-.58-23-.2-4.55.23-6.16-.8-6.15-5.55q.2-84.48,0-169c0-5.05,1.41-6.36,6.47-6.34,43.49.22,87,.1,130.47.18,3.53,0,5.64-1,7.4-4.34,3-5.72,6.73-11.11,10.32-16.53.95-1.42,3.31-2.5,2.46-4.3-.69-1.46-3-.84-4.63-.84q-74,0-148,0c-5.61,0-10.85.44-16.1,3.55C5.69,268.92.17,275.86.14,285.84q-.3,91,0,182c0,8.89,5,15.74,12.37,20.8,7,4.75,14.9,4.44,22.62,4.5,30.83.2,61.65.08,92.48.08v0q47,0,94,0c2.5,0,5.82.67,7.16-1.3,4.71-7,8.92-14.28,13.08-21.6C242.88,468.54,241.81,467.12,239.53,467.13Z'
        style={{ fill: '#333' }}
      />
      <path
        d='M260.13,198.84c0,3.83,1.38,4.22,4.51,2.8,5.71-2.58,11.49-5,17.27-7.42a6.24,6.24,0,0,0,4.29-6.4q-.12-75.45-.12-150.92c0-10.89,0-10.87,10.65-10.88q83.46,0,166.91-.17c3.82,0,3.92,2.44,4.05,4.87.2,3.65,0,7.32,0,11q0,55.21-.06,110.44c0,3.19.37,5.79,4.19,5.95,6.27.27,11.87,3,17.6,5,3.61,1.28,4.13-.12,4.21-2.92.08-2.5,0-5,0-7.49q0-62.48,0-124.94c0-13.58-11.76-28-27.81-27.7-29.64.61-59.3.17-88.95.18-30.16,0-60.31-.08-90.46,0-15.6.07-25.94,12-26.07,25.19C259.87,83.22,260.19,141,260.13,198.84Z'
        style={{ fill: '#333' }}
      />
      <path
        d='M469.57,748.15a91.41,91.41,0,0,0-16.16-11.75c-8.65-4.91-17.39-9.08-27.78-9-44.64.15-89.29,0-133.94.13-4.36,0-5.72-1.22-5.66-5.62.24-15.66,0-31.32.16-47,.06-4-1.13-6.44-4.48-8.59-5.91-3.82-11.58-8-17.18-12.3-1.2-.91-2-2.44-3.66-1.54-1.42.78-.7,2.4-.7,3.64,0,22.82.34,45.65-.12,68.46-.33,16.7,12.76,29.27,29.33,29.32,28.32.1,56.64,0,85,0q34.49,0,69,0c8.15,0,16.31.32,24.45-.49,1.53-.15,3.31-.36,3.92-1.7C472.43,750.05,470.6,749.08,469.57,748.15Z'
        style={{ fill: '#333' }}
      />
    </svg>
  );
};
