import React, { Component } from 'react';
import Joi from 'joi-browser';
import _ from 'lodash';
import http from '../../services/httpService';

class Form extends Component {
  state = {
    success: false,
    data: {
      mail: '',
    },
    errors: {},
    submitted: false,
    loading: false,
  };

  schema = {
    mail: Joi.string().email().required().label('Mail'),
  };

  clearData = () => {
    this.setState({
      data: {
        mail: '',
      },
      errors: {},
      submitted: true,
      loading: false,
    });

    document.getElementById('mailform').reset();
  };

  doSubmit = async () => {
    const apiUrl = 'https://lnt-mail-backend.herokuapp.com/api/mail/subscribe';
    try {
      const request = await http.post(apiUrl, {
        token: 'qYvtpmiVoMASaYdynH17',
        site: 'Apotheek Collin',
        ...this.state.data,
      });
      if (request) console.log(request);
      this.handleSuccess();
    } catch (err) {
      this.handleError();
    }
  };

  handleSuccess = () => {
    this.clearData();
    this.setState({ success: true });

    setTimeout(() => {
      this.setState({ success: false });
    }, 3000);
  };

  handleError = () => {
    const { errors } = this.state;
    errors['mail'] = 'There appears to be an error. Please try again.';
    this.setState(errors);
  };

  handleSubmit = (e) => {
    e.preventDefault(); // Prevent page reload when form submitting

    const errors = this.validate();
    this.setState({ errors: errors || {} });

    if (!_.isEmpty(errors)) {
      console.log('Form not submitted - Errors.', errors);
      return;
    }

    this.doSubmit();
  };

  validate = () => {
    const options = { abortEarly: false };
    const { error } = Joi.object(this.schema).validate(this.state.data, options);

    const errors = {};
    if (error && error.details) for (let item of error.details) errors[item.path[0]] = item.message;

    return errors;
  };

  handleChange = ({ currentTarget }) => {
    const data = { ...this.state.data };
    data[currentTarget.name] = currentTarget.value;

    this.setState({ data });
  };

  render() {
    const { styles } = this.props;
    const { errors, success } = this.state;

    return (
      <form className={styles.form} onSubmit={this.handleSubmit} id='mailform'>
        <div className={styles.form_inputs}>
          <input
            className={styles.form_input}
            type='text'
            name='mail'
            id='mail'
            placeholder='Enter your email'
            aria-required='true'
            onChange={this.handleChange}
          />

          <input className={styles.form_submit} type='submit' value='Notify me' />
        </div>

        <span className={`${styles.form_error} ${errors['mail'] ? styles.form_error_visible : ''} color-red`}>
          {errors['mail']}
        </span>
        <span className={`${styles.form_error} ${success ? styles.form_error_visible : ''} color-green`}>
          Success! You will get notified.
        </span>
      </form>
    );
  }
}

export default Form;
