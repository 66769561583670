import React from 'react';
import styles from '../../styles/modules/right.module.scss';
import Picture from '../blocks/picture';
import Form from './form';
import { Logo } from '../images/svg';

const buildYear = 2020;
const year = new Date(Date.now()).getUTCFullYear();

const Right = () => {
  return (
    <div className={styles.content}>
      <div className={styles.logo}>
        {/* <Picture src='logo' ext='png' alt="Lines 'n Tones Logo" extraClass={styles.logo_inner} /> */}
        <Logo />
      </div>

      <div className={styles.text_container}>
        <h2 className='font-bold color-gray'>Stay Tuned</h2>
        <h3 className='font-extra-bold color-gray'>We will launch soon</h3>
        <p className='color-light-gray'>Subscribe to get notified as soon as we launch!</p>
      </div>

      <Form styles={styles} />

      <div className={styles.legal}>
        <p className='color-gray'>
          Copyright &copy; {buildYear === year ? year : `${buildYear} - ${year}`}{' '}
          <a className='color-gray' href='https://linesntones.com' rel='author' style={{ verticalAlign: 'top' }}>
            Lines &apos;n Tones <span style={{ fontSize: '0.9rem', verticalAlign: 'top' }}>&reg;</span>
          </a>
          <br />
          All rights reserved.
        </p>
      </div>
    </div>
  );
};

export default Right;
