import React, { Component } from 'react';
import styles from '../../styles/modules/left.module.scss';

const targetDate = new Date(2020, 8, 27, 18, 0, 0, 0);

class Left extends Component {
  state = {
    weeks: 0,
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  };

  componentDidMount() {
    this.updateTime();
    setInterval(this.updateTime, 1000);
  }

  updateTime = () => {
    const currentDate = new Date(Date.now());
    const diff = Math.floor(Math.abs(targetDate - currentDate) / 1000);

    this.setState({
      weeks: this.weeksDiff(diff),
      days: this.daysDiff(diff),
      hours: this.hoursDiff(diff),
      minutes: this.minutesDiff(diff),
      seconds: this.secondsDiff(diff),
    });
  };

  secondsDiff = (diff) => {
    return diff % 60;
  };

  minutesDiff = (diff) => {
    return Math.floor(diff / 60) % 60;
  };

  hoursDiff = (diff) => {
    return Math.floor(diff / 60 / 60) % 24;
  };

  daysDiff = (diff) => {
    return Math.floor(diff / 60 / 60 / 24) % 7;
  };

  weeksDiff = (diff) => {
    return Math.floor(diff / 60 / 60 / 24 / 7);
  };

  render() {
    const { weeks, days, hours, minutes, seconds } = this.state;

    return (
      <div className={styles.countdown_container}>
        <div className={styles.countdown_element}>
          <span className={`${styles.digit} font-bold color-white`}>{weeks}</span>
          <span className={`${styles.name} font-bold color-white`}>Weeks</span>
        </div>
        <div className={styles.countdown_element}>
          <span className={`${styles.digit} font-bold color-white`}>{days}</span>
          <span className={`${styles.name} font-bold color-white`}>Days</span>
        </div>
        <div className={styles.countdown_element}>
          <span className={`${styles.digit} font-bold color-white`}>{hours}</span>
          <span className={`${styles.name} font-bold color-white`}>Hours</span>
        </div>
        <div className={styles.countdown_element}>
          <span className={`${styles.digit} font-bold color-white`}>{minutes}</span>
          <span className={`${styles.name} font-bold color-white`}>Minutes</span>
        </div>
        <div className={styles.countdown_element}>
          <span className={`${styles.digit} font-bold color-white`}>{seconds}</span>
          <span className={`${styles.name} font-bold color-white`}>Seconds</span>
        </div>
      </div>
    );
  }
}

export default Left;
