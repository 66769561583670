import React from 'react';

import styles from '../../styles/modules/home.module.scss';
import Left from './left';
import Right from './right';

const Home = () => {
  return (
    // <div className={styles.home_container}>
    <div className={styles.home}>
      <div className={styles.home__left}>
        <Left />
      </div>
      <div className={styles.home__right}>
        <Right />
      </div>
    </div>
    // </div>
  );
};

export default Home;
